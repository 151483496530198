import React from "react";
import "./App.scss";
import Features from "./components/Features/Features";
import LandingCover from "./components/LandingCover/LandingCover";
import Games from "./components/Games/Games";
import Reviews from "./components/Reviews/Reviews";
import Stats from "./components/Stats/Stats";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";

function App() {
  return (
    <div className="app">
      <Header />
      <LandingCover />
      <Games />
      <Features />
      <Stats />
      <Reviews />
      <Footer />
    </div>
  );
}

export default App;
