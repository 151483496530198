import React from "react";
import "./Features.scss";

import FeatureContent from "./FeatureContent";
import { FeatureList } from "./FeatureList";

function Features() {
  return (
    <section id="features" className="features">
      <div className="features_exclusive">Exclusive Features</div>
      <div className="features_content">
        {FeatureList.map((feature, index) => (
          <FeatureContent
            key={index}
            contentIcon={feature.icon}
            title={feature.title}
            description={feature.description}
            listItems={feature.listItems}
            listItemsSub={feature.listItemsSub}
            background={feature.background}
            className={index % 2 === 1 ? "row-reverse" : ""}
            highlight={feature.highlight}
          />
        ))}
      </div>
    </section>
  );
}

export default Features;
