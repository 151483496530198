import React, { useRef, useCallback } from "react";
import "./Games.scss";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { IconContext } from "react-icons";

// Games icons
import arenaOfValorIcon from "../../assets/gameIcons/aov.svg";
import brawlstarsIcon from "../../assets/gameIcons/brawlstars.svg";
import starcraftIcon from "../../assets/gameIcons/starcraft.svg";
import csIcon from "../../assets/gameIcons/cs.svg";
import dotaIcon from "../../assets/gameIcons/dota.svg";
import eafcIcon from "../../assets/gameIcons/eafc.svg";
import leagueIcon from "../../assets/gameIcons/league.svg";
import mlbbIcon from "../../assets/gameIcons/mlbb.svg";
import mobilelegendsIcon from "../../assets/gameIcons/mobilelegends.svg";
import overwatchIcon from "../../assets/gameIcons/overwatch.svg";
import rainbowsixIcon from "../../assets/gameIcons/rainbowsix.svg";
import rocketleagueIcon from "../../assets/gameIcons/rocketleague.svg";
import sc2Icon from "../../assets/gameIcons/sc2.svg";
import valorantIcon from "../../assets/gameIcons/valorant.svg";
import warcraftIcon from "../../assets/gameIcons/worldofwarcraft.svg";
import wildriftIcon from "../../assets/gameIcons/wildrift.svg";

// Games images
import cs2Image from "../../assets/games/counterstrike_cover.jpg";
import dotaImage from "../../assets/games/dota2_cover.jpg";
import leagueImage from "../../assets/games/leagueoflegends_cover.jpg";
import mobilelegendsImage from "../../assets/games/mobilelegends_cover.jpg";
import overwatch2Image from "../../assets/games/overwatch_cover.jpg";
import rainbowsixImage from "../../assets/games/rainbowsix_cover.jpg";
import rocketleagueImage from "../../assets/games/rocketleague_cover.jpg";
import scImage from "../../assets/games/starcraft_cover.jpg";
import sc2Image from "../../assets/games/starcraft2_cover.jpg";
import valorantImage from "../../assets/games/valorant_cover.jpg";
import brawlStarsImage from "../../assets/games/brawlstars_cover.jpg";
import arenaOfValorImage from "../../assets/games/arenaofvalor_cover.jpg";
import eafcImage from "../../assets/games/fifa_cover.jpg";
import warcraftImage from "../../assets/games/warcraft_cover.jpg";
import wildriftImage from "../../assets/games/wildrift_cover.jpg";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

// import required modules
import { Scrollbar } from "swiper/modules";

function Games() {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <section id="games" className="games">
      <div className="games_title">
        <span>15 Games Covered</span>
        <div className="games_title_subtitle">More games coming</div>
      </div>
      <div className="games_list">
        <Swiper
          ref={sliderRef}
          slidesPerView={5}
          scrollbar={{ draggable: true }}
          modules={[Scrollbar]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img src={dotaImage} alt="dota" className="standard_img" />
              <div className="hover_div">
                <img src={dotaIcon} alt="dota" className="hover_img" />
                <span>DOTA 2</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img src={cs2Image} alt="cs2" className="standard_img" />
              <div className="hover_div">
                <img src={csIcon} alt="cs2" className="hover_img" />
                <span>CS 2</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img
                src={valorantImage}
                alt="valorant"
                className="standard_img"
              />
              <div className="hover_div">
                <img src={valorantIcon} alt="valorant" className="hover_img" />
                <span>VALORANT</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img src={leagueImage} alt="league" className="standard_img" />
              <div className="hover_div">
                <img src={leagueIcon} alt="league" className="hover_img" />
                <span>LEAGUE OF LEGENDS</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img
                src={rocketleagueImage}
                alt="rocketleague"
                className="standard_img"
              />
              <div className="hover_div">
                <img
                  src={rocketleagueIcon}
                  alt="rocketleague"
                  className="hover_img"
                />
                <span>ROCKET LEAGUE</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img
                src={rainbowsixImage}
                alt="rainbowsix"
                className="standard_img"
              />
              <div className="hover_div">
                <img
                  src={rainbowsixIcon}
                  alt="rainbowsix"
                  className="hover_img"
                />
                <span>RAINBOW SIX</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img
                src={mobilelegendsImage}
                alt="mobilelegends"
                className="standard_img"
              />
              <div className="hover_div">
                <img
                  src={mobilelegendsIcon}
                  alt="mobilelegends"
                  className="hover_img"
                />
                <span>MOBILE LEGENDS</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img
                src={overwatch2Image}
                alt="overwatch2"
                className="standard_img"
              />
              <div className="hover_div">
                <img
                  src={overwatchIcon}
                  alt="overwatch2"
                  className="hover_img"
                />
                <span>OVERWATCH</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img src={sc2Image} alt="sc2" className="standard_img" />
              <div className="hover_div">
                <img src={sc2Icon} alt="sc2" className="hover_img" />
                <span>STARCRAFT 2</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img src={scImage} alt="sc" className="standard_img" />
              <div className="hover_div">
                <img src={sc2Icon} alt="sc" className="hover_img" />
                <span>STARCRAFT</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img
                src={brawlStarsImage}
                alt="brawl stars"
                className="standard_img"
              />
              <div className="hover_div">
                <img
                  src={brawlstarsIcon}
                  alt="brawl stars"
                  className="hover_img"
                />
                <span>BRAWL STARS</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img
                src={arenaOfValorImage}
                alt="arena of valor"
                className="standard_img"
              />
              <div className="hover_div">
                <img
                  src={arenaOfValorIcon}
                  alt="arena of valor"
                  className="hover_img"
                />
                <span>ARENA OF VALOR</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img src={eafcImage} alt="fifa" className="standard_img" />
              <div className="hover_div">
                <img src={eafcIcon} alt="fifa" className="hover_img" />
                <span>EA SPORTS FC</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img src={warcraftImage} alt="sc" className="standard_img" />
              <div className="hover_div">
                <img src={warcraftIcon} alt="sc" className="hover_img" />
                <span>WARCRAFT</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-card">
              <img src={wildriftImage} alt="sc" className="standard_img" />
              <div className="hover_div">
                <img src={wildriftIcon} alt="sc" className="hover_img" />
                <span>WILDRIFT</span>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div onClick={handlePrev}>
          <IconContext.Provider value={{ className: "prev-arrow" }}>
            <FaArrowLeft />
          </IconContext.Provider>
        </div>
        <div onClick={handleNext}>
          <IconContext.Provider value={{ className: "next-arrow" }}>
            <FaArrowRight />
          </IconContext.Provider>
        </div>
      </div>
      <div className="games_mobile">
        <img src={dotaIcon} alt="dota" />
        <img src={csIcon} alt="cs" />
        <img src={valorantIcon} alt="valorant" />
        <img src={leagueIcon} alt="league" />
        <img src={rocketleagueIcon} alt="rocketleague" />
        <img src={rainbowsixIcon} alt="rainbowsix" />
        <img src={mlbbIcon} alt="mlbb" />
        <img src={overwatchIcon} alt="overwatch" />
        <img src={sc2Icon} alt="sc2" />
        <img src={starcraftIcon} alt="sc" />
        <img src={brawlstarsIcon} alt="brawlstars" />
        <img src={arenaOfValorIcon} alt="arenaofvalor" />
        <img src={eafcIcon} alt="eafc" />
        <img src={warcraftIcon} alt="worldofwarcraft" />
        <img src={wildriftIcon} alt="wildrift" />
      </div>
    </section>
  );
}

export default Games;
