import React from "react";
import "./Stats.scss";
import CountUp from "react-countup";

function StatTile(props) {
  const { icon, number, representative } = props;

  return (
    <div className="stat-tile">
      <div className="stat-tile_icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="stat-tile_number">
        <CountUp
          end={number}
          duration={2}
          decimal="."
          decimals={number % 1 > 0 ? 1 : 0}
          enableScrollSpy
        />
        <span>K</span>
      </div>
      <div className="stat-tile_representative">{representative}</div>
    </div>
  );
}

export default StatTile;
