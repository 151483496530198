import React, { useState } from "react";
import "./Header.scss";
import logoLiquipedia from "../../assets/images/LP-logo.svg";
import { IoMenu } from "react-icons/io5";
import { Drawer } from "antd";
import PlayStore from "../../assets/images/PlayStore.svg";
import AppStore from "../../assets/images/AppStore.svg";
function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  window.addEventListener("resize", adapt);
  const scrollToSection = (e) => {
    if (isNavExpanded) {
      setIsNavExpanded(false);
    }
    const section = e.target.innerHTML.toLowerCase();
    const element = document.getElementById(section);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const toggleMenu = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  function adapt() {
    if (window.innerWidth >= 768) {
      setIsNavExpanded(false);
    }
  }

  const goToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.teamliquid.liquipedia.liquipedia_app",
      "_blank"
    );
  };

  const goToAppStore = () => {
    window.open(
      "https://apps.apple.com/us/app/liquipedia/id1640722331",
      "_blank"
    );
  };

  const goToLiquipedia = () => {
    window.open("https://liquipedia.net", "_blank");
  };

  return (
    <div className="header">
      <div className="header_menu">
        {!isNavExpanded && (
          <IoMenu
            onClick={toggleMenu}
            style={{
              fontSize: "24px",
              color: "white",
              cursor: "pointer",
            }}
          />
        )}
      </div>
      <div className="header_logo" onClick={goToLiquipedia}>
        <img src={logoLiquipedia} alt="Liquipedia Logo" />
      </div>
      <div className="header_nav">
        <div className="header_nav_item" onClick={scrollToSection}>
          Games
        </div>
        <div className="header_nav_item" onClick={scrollToSection}>
          Features
        </div>
        <div className="header_nav_item" onClick={scrollToSection}>
          Stats
        </div>
        <div className="header_nav_item" onClick={scrollToSection}>
          Reviews
        </div>
      </div>
      <Drawer
        placement="right"
        onClose={() => setIsNavExpanded(false)}
        open={isNavExpanded}
        width="100%"
      >
        <div className="header_mobile">
          <div className="header_nav_item" onClick={goToLiquipedia}>
            Home
          </div>
          <div className="header_nav_item" onClick={scrollToSection}>
            Games
          </div>
          <div className="header_nav_item" onClick={scrollToSection}>
            Features
          </div>
          <div className="header_nav_item" onClick={scrollToSection}>
            Stats
          </div>
          <div className="header_nav_item" onClick={scrollToSection}>
            Reviews
          </div>
          <div className="header_mobile-download">
            <img src={PlayStore} alt="Play Store" onClick={goToPlayStore} />
            <img src={AppStore} alt="App Store" onClick={goToAppStore} />
            <div className="header_mobile-download-count">400k+ Downloads</div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
