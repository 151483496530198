import React from "react";
import "./Footer.scss";
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import LpLogo from "../../assets/images/LP-logo.svg";
import PlayStore from "../../assets/images/PlayStore.svg";
import AppStore from "../../assets/images/AppStore.svg";

function Footer() {
  const goToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.teamliquid.liquipedia.liquipedia_app",
      "_blank"
    );
  };

  const goToAppStore = () => {
    window.open(
      "https://apps.apple.com/us/app/liquipedia/id1640722331",
      "_blank"
    );
  };

  const goToPrivacyPolicy = () => {
    window.open(
      "https://liquipedia.net/commons/Liquipedia:Privacy_policy",
      "_blank"
    );
  };

  const goToFeedback = () => {
    window.open(
      "https://forms.clickup.com/10617282/f/a40e2-53641/KUT74R97R6F36VXCM4",
      "_blank"
    );
  };

  const goToContact = () => {
    window.open("mailto:app@liquipedia.net", "_blank");
  };

  const goToDiscord = () => {
    window.open("https://discord.gg/liquipedia", "_blank");
  };

  const goToTwitter = () => {
    window.open("https://twitter.com/LiquipediaNet", "_blank");
  };

  return (
    <section id="footer" className="footer">
      <div className="footer_download">
        {window.innerWidth > 768 ? (
          <>
            <div className="footer_download-title">
              Download the Liquipedia App
            </div>
            <div className="footer_download-count">400k+ Downloads</div>
          </>
        ) : (
          <img src={LpLogo} alt="Liquipedia" />
        )}

        <div className="footer_download-buttons">
          <img src={PlayStore} alt="Play Store" onClick={goToPlayStore} />
          <img src={AppStore} alt="App Store" onClick={goToAppStore} />
        </div>
      </div>
      <div className="footer_top">
        <div className="footer_top_left">
          <div onClick={goToPrivacyPolicy}>Privacy Policy</div>
          <div onClick={goToFeedback}>Feedback</div>
          <div onClick={goToContact}>Contact</div>
        </div>
        <div className="footer_top_right">
          <FaDiscord onClick={goToDiscord} />
          <FaXTwitter onClick={goToTwitter} />
        </div>
      </div>
      <div className="footer_line"></div>
      <div className="footer_bottom">© Liquipedia. All rights reserved.</div>
    </section>
  );
}

export default Footer;
