import sparklesIcon from "../../assets/images/sparkles.svg";
import tournamentIcon from "../../assets/images/icon-tournament.svg";
import bellIcon from "../../assets/images/bell-active.svg";
import globeIcon from "../../assets/images/globe.svg";
import featuresImg1 from "../../assets/images/features-1.png";
import featuresImg2 from "../../assets/images/features-2.png";
import featuresImg3 from "../../assets/images/features-3.png";
import featuresImg4 from "../../assets/images/features-4.png";
import featuresImg5 from "../../assets/images/features-5.png";
import highlight1 from "../../assets/images/highlight1.png";
import highlight2 from "../../assets/images/highlight2.png";
import highlight3 from "../../assets/images/highlight3.png";
import highlight4 from "../../assets/images/highlight4.png";
import highlight5 from "../../assets/images/highlight5.png";
import eyeIcon from "../../assets/images/eye-icon.svg";

export const FeatureList = [
  {
    icon: sparklesIcon,
    background: featuresImg1,
    highlight: highlight1,
    title: "Personalized esports updates",
    description:
      "Create your own corner of esports by following the players, teams, and tournaments that you care about.",
    listItems: ["Live matches", "Recent results", "Detailed match schedule"],
    listItemsSub: [
      "Jump into live matches with a single tap",
      "Stay up to date with match scores",
      "Always know what's coming next and when",
    ],
  },
  {
    icon: tournamentIcon,
    background: featuresImg2,
    highlight: highlight2,
    title: "Never miss a match again",
    description:
      "Browse tournament info and standings, check match details, and quickly find streams and VODs",
    listItems: ["Match details", "Streams and VODs", "Results and standings"],
    listItemsSub: [
      "Check picks and bans, map vetoes, and match MVPs",
      "Jump into a live match or catch the VODs effortlessly",
      "Follow the results of a tournament, optimized for mobile screens",
    ],
  },
  {
    icon: bellIcon,
    background: featuresImg3,
    highlight: highlight3,
    title: "Notifications",
    description:
      "Enable push notifications to know when a match is starting or ending",
    listItems: ["Customize notifications", "Set and forget"],
    listItemsSub: [
      "Choose what reminders to receive for fully customizable experience",
      "Just follow your favorite teams, players and tournaments. We'll take care of the rest.",
    ],
  },
  {
    icon: globeIcon,
    background: featuresImg4,
    highlight: highlight4,
    title: "Localization in 13 languages",
    description:
      "Browse the app in the language of your choice (or join our community to help us expand our language options)",
    listItems: ["Largest esports geos supported", "More languages to come"],
    listItemsSub: [
      "App available in BR/PT, Russian, German, French, and more!",
      "All localization thanks to our amazing community",
    ],
  },
  {
    icon: eyeIcon,
    background: featuresImg5,
    highlight: highlight5,
    title: "Avoid spoilers",
    description: "Enjoy watching VODs in peace by enabling spoiler-free mode",
    listItems: [
      "Hide/show results with one tap",
      "Extended spoiler-free mode coming",
    ],
    listItemsSub: [
      "Switch between showing and hiding results with ease",
      "Hide tournament standings and upcoming matches for full spoiler-free experience (coming soon™)",
    ],
  },
];
