import React from "react";
import "./Stats.scss";
import StatTile from "./StatTile";
import TeamsIcon from "../../assets/images/Teams-icon.svg";
import PlayersIcon from "../../assets/images/Players-icon.svg";
import TournamentsIcon from "../../assets/images/Tournaments-icon.svg";
import CalendarIcon from "../../assets/images/Calendar-icon.svg";

function Stats() {
  return (
    <section id="stats" className="stats">
      <div className="stats_title">Stats</div>
      <div className="stats_subtext">
        The most extensive esports app in the world
      </div>
      <div className="stats_tiles">
        <StatTile icon={TeamsIcon} number="14.7" representative="Teams" />
        <StatTile icon={PlayersIcon} number="46.5" representative="Players" />
        <StatTile
          icon={TournamentsIcon}
          number="116"
          representative="Tournaments"
        />
        <StatTile
          icon={CalendarIcon}
          number="5"
          representative="Matches/Week"
        />
      </div>
    </section>
  );
}

export default Stats;
