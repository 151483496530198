import React from "react";
import "./LandingCover.scss";
import PlayStore from "../../assets/images/PlayStore.svg";
import AppStore from "../../assets/images/AppStore.svg";
import landingImageSource from "../../assets/images/lpheader.jpg";

function LandingCover() {
  const goToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.teamliquid.liquipedia.liquipedia_app",
      "_blank"
    );
  };

  const goToAppStore = () => {
    window.open(
      "https://apps.apple.com/us/app/liquipedia/id1640722331",
      "_blank"
    );
  };
  return (
    <div
      className="landing"
      style={{
        backgroundImage: `url(${landingImageSource})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="landing_download">
        <div className="landing_download-title">
          Download the
          {window.innerWidth < 768 ? <br /> : " "}
          Liquipedia App
        </div>
        <div className="landing_download-count">400k+ Downloads</div>
        <div className="landing_download-buttons">
          <img src={PlayStore} alt="Play Store" onClick={goToPlayStore} />
          <img src={AppStore} alt="App Store" onClick={goToAppStore} />
        </div>
      </div>
    </div>
  );
}

export default LandingCover;
