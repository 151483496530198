export const ReviewList = [
  {
    comment:
      "Excellent app, very much appreciated. The provided user experience is one of the best I’ve seen so far. Keep up the good work 💯👌 ",
    author: "ahmedisam99",
    score: 5,
  },
  {
    comment:
      "Great app for information about games, I follow everyone here! Excellent translation into Portuguese",
    author: "Laís Leite",
    score: 5,
  },
  {
    comment:
      "Liquipedia is a unique product for the world of e-sports, because, perhaps, there is simply no other similar knowledge base. This project definitely has a lot of room to grow and a lot of work to be done, so I'm glad that this work is actually being done and now we can enjoy Liquipedia right on our phones.",
    author: "Anton Muzyka",
    score: 5,
  },
  {
    comment:
      "I usually don’t write reviews but I thought I really have to send a message for all the people who are working on this app. I want to tell you guys to keep up the good work and please keep updating and making the app better and better because it has so much potential and future. I fully support you guys and I do see a bright future for this app. Of course there are lots of bugs and cons that I hope you guys fix them in the future and I know you will. Keep updating the app and keep up the good work my friends 🧡.",
    author: "vHassan31",
    score: 4,
  },
  {
    comment:
      "The developer released spoiler free mode and there’s a paid option to remove ads. Nice!",
    author: "AJFORTHEWIN",
    score: 5,
  },
  {
    comment:
      "I love the introduction to the app being able to choose your favorite games, teams and players to follow and scrolling through match results like instagram stories works surprisingly well.",
    author: "Wendys.gaming",
    score: 4,
  },
  {
    comment: "Probably the best eSports app.",
    author: "Irénée Girard",
    score: 4,
  },
  {
    comment: "Best looking esport UI",
    author: "Zachary Stanis-Wally",
    score: 5,
  },
  {
    comment: "Excellent app",
    author: "Alina I",
    score: 5,
  },
  {
    comment: "Cool, I recommend it.",
    author: "Justyna Grzesiak",
    score: 5,
  },
  {
    comment: "Excellent",
    author: "William Kaye",
    score: 5,
  },
  {
    comment: "Amazing",
    author: "PROTECTION XYZ",
    score: 5,
  },
];
