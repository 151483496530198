import React from "react";
import "./Features.scss";
import bullet from "../../assets/images/Bullet.svg";

function FeatureContent(props) {
  const {
    contentIcon,
    title,
    description,
    listItems,
    listItemsSub = [],
    background,
    highlight,
    className = "",
  } = props;
  return (
    <div className="feature-content">
      <div
        className="feature-content_image"
        style={{
          backgroundImage: `url(${highlight})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(60px)",
        }}
      ></div>
      <div className={`feature-content_item ${className}`}>
        <div className="feature-content_item_sub">
          <div className="feature-content_item_sub_icon">
            <img src={contentIcon} alt={title} />
            <div className="feature-content_item_sub_title">{title}</div>
          </div>
          {window.innerWidth > 768 && (
            <div className="feature-content_item_sub_description">
              {description}
            </div>
          )}
          <div className="feature-content_item_sub_list">
            {listItems?.map((item, index) => (
              <div key={index} className="feature-content_list-item">
                <span className="feature-content_list-item_title">
                  <img src={bullet} alt="Bullet"></img>
                  {item}
                </span>
                <div className="feature-content_list-item_sub">
                  {listItemsSub[index]}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="feature-content_item_image">
          <img src={background} alt={title} />
        </div>
      </div>
    </div>
  );
}

export default FeatureContent;
