import React, { useState, useEffect } from "react";
import "./Reviews.scss";
import ReviewCard from "./ReviewCard";
import { ReviewList } from "./ReviewList";

function Reviews() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  const [showAllReviews, setShowAllReviews] = useState(false);

  useEffect(() => {
    const adapt = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };
    window.addEventListener("resize", adapt);
    return () => window.removeEventListener("resize", adapt);
  }, []);

  const reviewsToShow = showAllReviews
    ? ReviewList
    : isSmallScreen
    ? ReviewList.slice(0, 3)
    : ReviewList.slice(0, 6);

  return (
    <section id="reviews" className="reviews">
      <div className="reviews_title">Reviews</div>
      <div className="reviews_tiles">
        {reviewsToShow.map((review, index) => (
          <ReviewCard
            key={index}
            comment={review.comment}
            author={review.author}
            score={review.score}
          />
        ))}
      </div>
      <div
        className="reviews_all"
        onClick={() => setShowAllReviews((prev) => !prev)}
      >
        {showAllReviews ? "Show fewer reviews" : "Show all reviews"}
      </div>
    </section>
  );
}

export default Reviews;
