import React, { useState } from "react";
import { Modal, Rate, Button } from "antd";
import "./Reviews.scss";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { ReviewList } from "./ReviewList";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";

const CheckCommentModal = NiceModal.create(({ comment }) => {
  const allReviews = ReviewList;
  const [currentIndex, setCurrentIndex] = useState(
    allReviews.findIndex((review) => review.comment === comment)
  );
  const review = allReviews[currentIndex];
  const checkCommentModal = useModal();

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : allReviews.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < allReviews.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <Modal
      open={checkCommentModal.visible}
      onOk={checkCommentModal.hide}
      onCancel={checkCommentModal.hide}
      afterClose={checkCommentModal.remove}
      centered
      footer={null}
    >
      <div className="modal-content">
        <Button
          type="text"
          onClick={handlePrev}
          icon={<FaArrowLeft />}
          className="modal-content-button"
        />
        <div className="modal-content-comment">
          <div className="review-card_rating">
            <Rate disabled defaultValue={review.score} count={review.score} />
            <div className="review-card_rating-total-modal">
              {review.score}/5
            </div>
          </div>
          <div className="review-card_comment">{review.comment}</div>
          <div className="review-card_author">— {review.author}</div>
        </div>
        <Button
          type="text"
          onClick={handleNext}
          icon={<FaArrowRight />}
          className="modal-content-button"
        />
        <div className="modal-content-progress">
          {allReviews.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
            ></span>
          ))}
        </div>
      </div>
    </Modal>
  );
});

function ReviewCard(props) {
  const { comment, author, score } = props;
  const checkCommentModal = useModal(CheckCommentModal);

  return (
    <div className="review-card">
      <div className="review-card_rating">
        <Rate disabled defaultValue={score} />
        <div className="review-card_rating-total">{score}/5</div>
      </div>
      <div className="review-card_comment">
        {comment.length > 75 ? (
          <>
            <div>
              {comment.slice(
                0,
                comment.indexOf(" ", 75) !== -1
                  ? comment.indexOf(" ", 75)
                  : comment.length
              )}
            </div>
            <div
              className="review-card_comment_read-more"
              onClick={() => {
                checkCommentModal.show({ comment });
              }}
            >
              ...Read more
            </div>
          </>
        ) : (
          comment
        )}
      </div>
      <div className="review-card_author">— {author}</div>
    </div>
  );
}

export default ReviewCard;
